import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import Video from "../components/videoPlayer"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      imageOne: file(relativePath: { eq: "index/home-1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Home" />
      <Video
        videoSrcURL="https://player.vimeo.com/video/359871226?portrait=0"
        videoTitle="intro"
      />
      <Img fluid={data.imageOne.childImageSharp.fluid} />
    </Layout>
  )
}

export default IndexPage
